import { Link } from "react-router-dom";
require("../../lib/helper");

const Project = () => {



    const render = () => {

        return (
            <>
                <div className="container-fluid bg-dark pt-5 my-5 px-0" style={{ direction: "ltr" }}>
                    <div className="text-center mx-auto mt-5 wow fadeIn" data-wow-delay="0.1s" style={{ maxWidth: "600px" }}>
                        <h1 className="display-5 text-white mb-5">עבודות שלי</h1>
                    </div>
                    <div className="owl-carousel project-carousel wow fadeIn" data-wow-delay="0.1s">
                        <Link className="project-item">
                            <img className="img-fluid" src="img/works/1.jpg" alt="הנמכת תקרה מגבס והתקנת ספוטים" />
                            <div className="project-title">
                                <h5 className="text-info mb-0">הנמכת תקרה מגבס והתקנת ספוטים</h5>
                            </div>
                        </Link>
                        <Link className="project-item">
                            <img className="img-fluid" src="img/works/2.jpg" alt="הנמכת תקרה, ותאורה נסתרת לסלון" />
                            <div className="project-title">
                                <h5 className="text-info mb-0">הנמכת תקרה, ותאורה נסתרת לסלון</h5>
                            </div>
                        </Link>
                        <Link className="project-item">
                            <img className="img-fluid" src="img/works/3.jpg" alt="התקנת מאורר תקרה חכם עם שלט" />
                            <div className="project-title">
                                <h5 className="text-info mb-0">התקנת מאורר תקרה חכם עם שלט</h5>
                            </div>
                        </Link>
                        <Link className="project-item">
                            <img className="img-fluid" src="img/works/5.jpg" alt="מפסק תאורה חכם" />
                            <div className="project-title">
                                <h5 className="text-info mb-0">מפסק תאורה חכם</h5>
                            </div>
                        </Link>
                        <Link className="project-item">
                            <img className="img-fluid" src="img/works/6.jpg" alt="התקנת תאורת קיר" />
                            <div className="project-title">
                                <h5 className="text-info mb-0">התקנת תאורת קיר</h5>
                            </div>
                        </Link>
                        <Link className="project-item">
                            <img className="img-fluid" src="img/works/7.jpg" alt="התקנה והרכבת שנדליר בחדר שינה" />
                            <div className="project-title">
                                <h5 className="text-info mb-0">התקנה והרכבת שנדליר בחדר שינה</h5>
                            </div>
                        </Link>
                        <Link className="project-item">
                            <img className="img-fluid" src="img/works/8.jpg" alt="החלפת ברז חצי צול" />
                            <div className="project-title">
                                <h5 className="text-info mb-0">החלפת ברז חצי צול</h5>
                            </div>
                        </Link>
                    </div>
                </div>
            </>

        )


    }

    return render();

}


export default Project;
