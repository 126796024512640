

const Facts = ()=>{

    const render = ()=>{
        return(
        <>
            <div class="container-fluid facts my-5 p-5">
        <div class="row g-5">
            <div class="col-md-6 col-xl-3 wow fadeIn" data-wow-delay="0.1s">
                <div class="text-center border p-5">
                    <i class="fa fa-certificate fa-3x text-white mb-3"></i>
                    <h1 class="display-2 text-info mb-0" data-toggle="counter-up">10</h1>
                    <span class="fs-5 fw-semi-bold text-white">שנות ניסיון</span>
                </div>
            </div>
            <div class="col-md-6 col-xl-3 wow fadeIn" data-wow-delay="0.3s">
                <div class="text-center border p-5">
                    <i class="fa fa-users-cog fa-3x text-white mb-3"></i>
                    <h1 class="display-2 text-info mb-0" data-toggle="counter-up">635</h1>
                    <span class="fs-5 fw-semi-bold text-white"> ביקורים - אחריות</span>
                </div>
            </div>
            <div class="col-md-6 col-xl-3 wow fadeIn" data-wow-delay="0.5s">
                <div class="text-center border p-5">
                    <i class="fa fa-users fa-3x text-white mb-3"></i>
                    <h1 class="display-2 text-info mb-0" data-toggle="counter-up">957</h1>
                    <span class="fs-5 fw-semi-bold text-white">לקוחות מרוצים</span>
                </div>
            </div>
            <div class="col-md-6 col-xl-3 wow fadeIn" data-wow-delay="0.7s">
                <div class="text-center border p-5">
                    <i class="fa fa-check-double fa-3x text-white mb-3"></i>
                    <h1 class="display-2 text-info mb-0" data-toggle="counter-up">1039</h1>
                    <span class="fs-5 fw-semi-bold text-white">פרויקטים שהסתיימו</span>
                </div>
            </div>
        </div>
            </div>
        </>
        )
    }

    return render();
}


export default Facts;
