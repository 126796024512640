

const Service = ()=>{

    const render = ()=>{
        return(
        <>
        <div class="container-xxl py-5" id="myservices">
        <div class="container">
            <div class="text-center mx-auto pb-4 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "600px"}}>
                <h1 class="display-5 mb-4">השירותים שלי</h1>
            </div>
            <div class="row gy-5 gx-4">
                <div class="col-md-6 col-lg-3 wow fadeInUp" data-wow-delay="0.1s">
                    <div class="service-item">
                        <img class="img-fluid" src="img/service-1.jpg" alt="התקנת גופי תאורה" />
                        <div class="service-img">
                            <img class="img-fluid" src="img/service-1.jpg" alt="התקנת גופי תאורה" />
                        </div>
                        <div class="service-detail">
                            <div class="service-title">
                                <hr class="w-25" />
                                <h3 class="mb-0">התקנת גופי תאורה ומאווררי תקרה</h3>
                                <hr class="w-25" />
                            </div>
                            <div class="service-text">
                                <p class="text-white mb-0">
                         כחלק מנסיוני הוא בהרכבת גופי תאורה, מאווררי תקרה, ומוצרי חשמל חכמים.
                                  חשוב מאוד ניסיון בהרכבה בייחוד גופים כבדים על מנת שלא יפלו חלילה
                                  וחיבור הארקה כראוי על מנת לאפשר שימוש בטוח במוצר.
                                </p>
                            </div>
                        </div>
                        <a class="btn btn-light">קרא עוד</a>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 wow fadeInUp" data-wow-delay="0.3s">
                    <div class="service-item">
                        <img class="img-fluid" src="img/service-7.jpg" alt="הרכבת ריהוט ותיקונים" />
                        <div class="service-img">
                            <img class="img-fluid" src="img/service-7.jpg" alt="הרכבת ריהוט ותיקונים" />
                        </div>
                        <div class="service-detail">
                            <div class="service-title">
                                <hr class="w-25" />
                                <h3 class="mb-0">הרכבת ריהוט ותיקונים</h3>
                                <hr class="w-25" />
                            </div>
                            <div class="service-text">
                                <p class="text-white mb-0">
                                    הרכבה שגויה של רהיטים עשויה לפגוע בהם לכן יש חשיבות בידע בהרכה.
                                    אשמח להרכיב עבורם, עם אחריות.
                                    </p>
                            </div>
                        </div>
                        <a class="btn btn-light" >קרא עוד</a>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 wow fadeInUp" data-wow-delay="0.5s">
                    <div class="service-item">
                        <img class="img-fluid" src="img/service-4.jpg" alt="התקנת אביזרי מקלחת, ברזים, ואיטום" />
                        <div class="service-img">
                            <img class="img-fluid" src="img/service-4.jpg" alt="התנקת אביזרי מקלחת, ברזים, ואיטום" />
                        </div>
                        <div class="service-detail">
                            <div class="service-title">
                                <hr class="w-25" />
                                <h3 class="mb-0">התקנת אביזרי מקלחת, ברזים, ואיטום</h3>
                                <hr class="w-25" />
                            </div>
                            <div class="service-text">
                                <p class="text-white mb-0">
                                 התקנת אביזרים לאמבטיה/למקלחת כמו למשל מוט פינוק, מדפים, ועוד...
                                </p>
                            </div>
                        </div>
                        <a class="btn btn-light" >קרא עוד</a>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 wow fadeInUp" data-wow-delay="0.5s">
                    <div class="service-item">
                        <img class="img-fluid" src="img/service-5.jpg" alt="תיקוני קירות, החלקה וצביעה" />
                        <div class="service-img">
                            <img class="img-fluid" src="img/service-5.jpg" alt="תיקוני קירות, החלקה וצביעה" />
                        </div>
                        <div class="service-detail">
                            <div class="service-title">
                                <hr class="w-25" />
                                <h3 class="mb-0">תיקון קירות, החלקה וצביעה</h3>
                                <hr class="w-25" />
                            </div>
                            <div class="service-text">
                                <p class="text-white mb-0">
                                    כחלק מנסיוני בתחזוקה, הוא תיקון קירות, החלקה וצביעתם.
                                    גם לפי קווים ועיצובים שאתם אוהבים ע״י שימוש בלייזר.
                                    קירות חלקות וצבעים בהחלט משפרים את המראה של הדירה.
                                </p>
                            </div>
                        </div>
                        <a class="btn btn-light" >קרא עוד</a>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 wow fadeInUp" data-wow-delay="0.5s">
                    <div class="service-item">
                        <img class="img-fluid" src="img/service-3.jpg" alt="החלפת רשתות נגד יתושים" />
                        <div class="service-img">
                            <img class="img-fluid" src="img/service-3.jpg" alt="החלפת רשתות נגד יתושים" />
                        </div>
                        <div class="service-detail">
                            <div class="service-title">
                                <hr class="w-25" />
                                <h3 class="mb-0">החלפת רשתות נגד יתושים</h3>
                                <hr class="w-25" />
                            </div>
                            <div class="service-text">
                                <p class="text-white mb-0">
                                        רשתות נגד יתושים זקוקות מדי פעם לחידוש בעקבות פגעי מזג האוויר,
                                        או אחיזה ברשת שסוגרים או פותחים את החלון.
                                        לכן נדרש החלפה לרשת חדשה ועמידה שתחזיק לאורך זמן.
                                </p>
                            </div>
                        </div>
                        <a class="btn btn-light" >קרא עוד</a>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 wow fadeInUp" data-wow-delay="0.5s">
                    <div class="service-item">
                        <img class="img-fluid" src="img/service-6.jpg" alt="בניית אתרי תדמית ואיקומרס" />
                        <div class="service-img">
                            <img class="img-fluid" src="img/service-6.jpg" alt="בניית אתרי תדמית ואיקומרס" />
                        </div>
                        <div class="service-detail">
                            <div class="service-title">
                                <hr class="w-25" />
                                <h3 class="mb-0">בניית אתרי תדמית ואיקומרס</h3>
                                <hr class="w-25" />
                            </div>
                            <div class="service-text">
                                <p class="text-white mb-0">
                                    לא כל יום פוגשים הנדימן שהוא גם מהנדס מחשבים מנוסה עם עשר שנות ניסיון.
                                    אני יכול לבנות עבורך אתר תדמית מדהים, אפלקציה. וגם אתר מכירות ברמה הגבוהה ביותר.
                                </p>
                            </div>
                        </div>
                        <a class="btn btn-light" >קרא עוד</a>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 wow fadeInUp" data-wow-delay="0.5s">
                    <div class="service-item">
                        <img class="img-fluid" src="img/service-8.jpg" alt="תיקון נזילות במזגן" />
                        <div class="service-img">
                            <img class="img-fluid" src="img/service-8.jpg" alt="תיקון נזילות במזגן" />
                        </div>
                        <div class="service-detail">
                            <div class="service-title">
                                <hr class="w-25" />
                                <h3 class="mb-0">תיקון נזילות במזגן</h3>
                                <hr class="w-25" />
                            </div>
                            <div class="service-text">
                                <p class="text-white mb-0">
                                    כחלק מנסיוני של תחזוקה, הוא תיקון נזילות מזגנים.
                                    לרוב נזילה במזגן נוצרת מסתימה של צינור הניקוז, שיפוע לקוי
                                    או שבר בן הצינור לאגן של המזגן.
                                </p>
                            </div>
                        </div>
                        <a class="btn btn-light" >קרא עוד</a>
                    </div>
                </div>
                <div class="col-md-6 col-lg-3 wow fadeInUp" data-wow-delay="0.5s">
                    <div class="service-item">
                        <img class="img-fluid" src="img/service-9.jpg" alt="החלפת אריכים שבורים, וחידוש רובה" />
                        <div class="service-img">
                            <img class="img-fluid" src="img/service-9.jpg" alt="החלפת אריכים שבורים וחידוש רובה" />
                        </div>
                        <div class="service-detail">
                            <div class="service-title">
                                <hr class="w-25" />
                                <h3 class="mb-0">החלפת אריכים שבורים, וחידוש רובה.</h3>
                                <hr class="w-25" />
                            </div>
                            <div class="service-text">
                                <p class="text-white mb-0">
                                    מדי פעם עלולים אריכים להישבר כתוצאה מנפילה של חפצים כבדים, 
                                    או בעקבות מילוי לקוי של האריח.
                                יש לתקן זאת על מנת למנוע חדירת מים מתחת לאריח.
                                </p>
                            </div>
                        </div>
                        <a class="btn btn-light" >קרא עוד</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
        </>
        )
    }

    return render();
}


export default Service;
