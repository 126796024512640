import React from 'react';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import MainLayout from "./layouts/MainLayout";
import Main from "./components/Main/Main";
import Service from './components/Service/Service';
import About from './components/About/About';
import { createRoot } from "react-dom/client";
import Features from './components/Features/Features';
import Contact from './components/Contact/Contact';
require("./lib/main");

// const express = require('express');
// const path = require('path');
// const app = express();

// app.use(express.static(path.join(__dirname, 'build')));

// // PATH CONFIGURATION TO RESPOND TO A REQUEST TO STATIC ROUTE REQUEST BY SERVING index.html
// app.get('/*', function (req, res) {
//   res.sendFile(path.join(__dirname, 'build', 'index.html'));
// });

// app.listen(5000);
// console.log('Server is listening on http://localhost:5000');

// // const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    path: "*",
    element: <> Page not found !</>
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "",
        element: <Main />
      },
      {
        path: "service",
        element: <Service />
      },
      {
        path: "about",
        element: <About />
      },
      {
        path: "whyme",
        element: <Features />
      },
      {
        path: "contact",
        element: <Contact />
      }
    ]
  }
]);


createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
