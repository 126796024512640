import React from 'react'
import Carousel from '../Carousel/Carousel'
import About from '../About/About'
import Features from '../Features/Features'
import Facts from '../Facts/Facts'
import Service from '../Service/Service'
import CustomersSay from '../CustomersSay/CustomersSay'
import Project from "../Project/Project";

function Main() {
    return (
        <>
            <Carousel />
            <About />
            <Facts />
            <Features />
            <Service />
            <Project />
            {/* <Project/> */}
            <CustomersSay />
        </>
    )
}

export default Main



