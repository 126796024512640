import { Link } from "react-router-dom";
import $ from 'jquery';

const Footer = () => {

    const navClicked = (classname) => {
        $(".nav-item.nav-link").removeClass("active");
        $("." + classname).toggleClass("active");
        $('.back-to-top')[0].click();
    }


    const render = () => {
        return (
            <>
                <div class="container-fluid bg-dark footer mt-5 py-5 wow fadeIn" data-wow-delay="0.1s">
                    <div class="container py-5">
                        <div class="row g-5">
                            <div class="col-lg-3 col-md-6">
                                <h5 class="text-white mb-4">לינקים מהירים</h5>
                                <Link onClick={() => navClicked("about1")} to="/about" class="btn btn-link" >אודותי</Link>
                                <Link onClick={() => navClicked("contact1")} to="/contact" class="btn btn-link" >יצירת קשר</Link>
                                <Link onClick={() => navClicked("service1")} to="/service" class="btn btn-link" >שירותים</Link>
                            </div>
                            <div class="col-lg-3 col-md-6">
                                <h5 class="text-white mb-4">זמינות לעבודה</h5>
                                <p class="mb-1">ראשון - שלישי</p>
                                <h6 class="text-light">07:30 am - 07:00 pm</h6>
                                <p class="mb-1">רביעי</p>
                                <h6 class="text-light">07:30 am - 16:00 pm</h6>
                                <p class="mb-1">שישי,שבת</p>
                                <h6 class="text-light">לא עובד</h6>
                            </div>
                            <div class="col-lg-4 col-md-6" id="contact">
                                <h5 class="text-white mb-4">צור קשר</h5>
                                <a class="btn btn-link text-light remove_arrow" target="_blank" href="https://wa.me/0549508756">
                                    <i class="bi bi-whatsapp">  whatup </i>
                                </a>
                                <a class="btn btn-link text-light remove_arrow" href="tel:0549508756">
                                    <i class="bi bi-phone"> טלפון  </i>
                                </a>
                                <a class="btn btn-link text-light remove_arrow" href="mailto:tzachi222@gmail.com">
                                    <i class="bi bi-envelope">  אי-מייל </i>
                                </a>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="container-fluid copyright bg-dark py-4">
                    <div class="container text-center">
                        <p class="mb-2">Copyright &copy; <a className="fw-semi-bold" href="#">צחי בן חמו הנדימן</a>, כל הזכויות שמורות.
                        </p>
                        <p class="mb-0">Designed By <a class="fw-semi-bold" href="https://htmlcodex.com">HTML Codex</a> </p>
                    </div>
                </div>

                <a href="#" class="btn btn-lg btn-info btn-lg-square rounded-circle back-to-top">
                    <i class="bi bi-arrow-up"></i></a>
            </>
        )
    }

    return render();
}


export default Footer;
