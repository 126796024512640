

const Topbar = () => {

    const render = () => {
        return (
            <>
                <div className="container-fluid bg-dark px-0">
                    <div className="row g-0 d-none d-lg-flex">
                        <div className="col-lg-6 ps-5 text-start">
                            <div className="h-100 d-inline-flex align-items-center text-white">
                                <span> קופון הנחה 10% </span>
                                <span className="cupon1">DIS-9756 </span>

                            </div>
                        </div>

                        <div className="col-lg-6 text-start">
                            <div className="h-100 topbar-right d-inline-flex align-items-center text-white py-2 px-5">
                                <a className="callme" href="tel:⁦+9729508756⁩">
                                    <span className="fs-5 fw-bold me-2"><i className="fa fa-phone-alt me-2"></i>
                                        צרו קשר </span>
                                    <span className="fs-5 fw-bold">054-950-8756</span>
                                </a>
                                <div className="line_1">|</div>
                                <a className="btn btn-link text-light remove_arrow" target="_blank" rel="noreferrer" href="https://wa.me/0549508756">
                                    <i className="bi bi-whatsapp">  whatup </i>
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        )
    }

    return render();
}


export default Topbar;
