

const About = ()=>{

    const render = ()=>{
        return(
        <>
            <div class="container-xxl py-5" id="about">
                <div class="container">
                <div class="row g-5">
                    <div class="col-lg-6 remove-space">
                        <div class="row gx-3 h-100">
                            <div class="col-12 align-self-start wow fadeInUp" data-wow-delay="0.1s">
                                <img class="img-fluid" src="img/me.png" />
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                        <h1 class="display-5 mb-4">מי אני ? </h1>
                        <p class="mb-4">
                            שמי צחי בן חמו בן 35,
                            תואר ראשון בהנדסת מחשבים
                            מגיל קטן עבדתי כעוזר לאבי המנוח שהיה חשמלאי ועשיתי עבודות רבותֿ
                            עם השנים הגדלתי את הידע בתחומים נוספים שקשורים לענף
                            והתחלתי לעשות עבודות אצל אנשים 
                            קיבלתי פידבקים חיובים ואנשים המליצו עלי
                            אני מאוד אוהב לעזור, וזה החיבור הגדול ביותר שלי למקצוע
                            אני מאוד מסור לעבודה ונותן אחריות לכל דבר שאני עושה
                            הכי חשוב לי שהלקוח יהיה שמח וארגיש שעשיתי עוד מעשה טוב
                            אשמח מאוד לעזור לך בכל עניין
                            אפשר לשאול שאלות בווסאפ או להתקשר אלי
                            ברכה והצלחה, שלכם
                            צחי.
                        </p>
                        <div class="d-flex align-items-center mb-4">
                            <div class="flex-shrink-0 bg-info p-4">
                                <h1 class="display-2">10</h1>
                                <h5 class="text-white">שנים</h5>
                                <h5 class="text-white">של ניסיון</h5>
                            </div>
                            <div class="ms-4">
                                <p><i class="fa fa-check text-info me-2"></i> תמיכה ואחריות על העבודה </p>
                                <p><i class="fa fa-check text-info me-2"></i> יעיל ומהיר </p>
                                <p><i class="fa fa-check text-info me-2"></i> אדיב ושירותי </p>
                                <p><i class="fa fa-check text-info me-2"></i> מגיע בזמן </p>
                                <p><i class="fa fa-check text-info me-2"></i> מחיר נמוך ביחס לשוק </p>
                            </div>
                        </div>
                        <div class="row pt-2">
                            <div class="col-sm-6">
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0 btn-lg-square rounded-circle bg-info">
                                        <i class="fa fa-envelope-open text-white"></i>
                                    </div>
                                    <div class="ms-3">
                                        <p class="mb-2">המייל שלי</p>
                                        <h5 class="mb-0">tzachi222@gmail.com</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="d-flex align-items-center">
                                    <div class="flex-shrink-0 btn-lg-square rounded-circle bg-info">
                                        <i class="fa fa-phone-alt text-white"></i>
                                    </div>
                                    <div class="ms-3">
                                        <p class="mb-2">צרו קשר</p>
                                        <h5 class="mb-0">054-950-8756</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </>
        )
    }

    return render();
}


export default About;
