import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navbar/Navbar";
import Topbar from "../components/Topbar/Topbar";
import { Outlet } from "react-router-dom";

export default function MainLayout() {
    return (
        <>
            <Topbar />
            <Navbar />
            <Outlet />
            <Footer />
        </>
    )
}


 

