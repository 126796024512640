import React from 'react';

function Contact() {
    return (
        <>
            <div class="container-xxl py-5" id="contact">
                <div class="container">
                    <div class="row g-5">
                        <div class="col-lg-6 remove-space">
                            <div class="row gx-3 h-100">
                                <div class="col-12 align-self-start wow fadeInUp" data-wow-delay="0.1s" style={{ visibility: "visible", animationDelay: "0.1s", animationName: "fadeInUp" }}><img class="img-fluid" src="img/contact_us.jpg" alt="me"></img></div>
                            </div>
                        </div>
                        <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s" style={{ visibility: "visible", animationDelay: "0.5s", animationName: "fadeIn" }}>
                            <h1 class="display-5 mb-4">יצירת קשר</h1>
                            <p class="mb-4">
                                תודה רבה על האמון,
                                מצפה מאוד לשמוע ממכם בקרוב, כל שאלות, הערות, הארות או הצעות מחיר
                                אני כאן לשירותכם,<br></br>
                                צחי
                            </p>

                            <div class="row pt-2">
                                <div class="col-sm-6">
                                    <div class="d-flex align-items-center">
                                        <div class="flex-shrink-0 btn-lg-square rounded-circle bg-info"></div>
                                        <div class="ms-3">
                                            <p class="mb-2">המייל שלי</p>
                                            <h5 class="mb-0">tzachi222@gmail.com</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="d-flex align-items-center">
                                        <div class="flex-shrink-0 btn-lg-square rounded-circle bg-info"></div>
                                        <div class="ms-3">
                                            <p class="mb-2">צרו קשר</p>
                                            <h5 class="mb-0">054-950-8756</h5>
                                        </div>
                                    </div>
                                </div>
                                <br /><br /><br />
                                <div class="col-sm-6">
                                    <div class="d-flex align-items-center">
                                        <div class="flex-shrink-0 btn-lg-square rounded-circle bg-info"></div>
                                        <div class="ms-3">
                                            <p class="mb-2">פתיחת שיחה בוואטסאפ</p>
                                            <h5 class="mb-0"><a style={{ color: "blue" }} href="https://wa.me/0549508756">
                                                <i class="bi bi-whatsapp">  whatup </i>
                                            </a>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Contact


