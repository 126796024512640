import * as React from "react";
import { Link } from "react-router-dom";
import $ from 'jquery';
import { useEffect } from "react";

const Navbar = () => {


    useEffect(() => {
        const currentNav = window.location.pathname;
        $(".nav-item.nav-link").removeClass("active");

        switch (currentNav) {
            case "/":
                $(".daf_bait").toggleClass("active");
                break;
            case "/service":
                $(".service1").toggleClass("active");
                break;
            case "/about":
                $(".about1").toggleClass("active");
                break;
            case "/whyme":
                $(".whyme1").toggleClass("active");
                break;
            case "/contact":
                $(".contact1").toggleClass("active");
                break;
            default:
                $(".daf_bait").toggleClass("active");
        }
    }, []);

    const render = () => {

        const navClicked = (e) => {
            $(".nav-item.nav-link").removeClass("active");
            e.currentTarget.classList.toggle("active");
            $(".me-0").click();
            $('.back-to-top')[0].click();
        }

        const navClickedLogo = () => {
            $(".nav-item.nav-link").removeClass("active");
            $(".daf_bait").toggleClass("active");
            $('.back-to-top')[0].click();
        }

        return (
            <>
                <nav class="navbar navbar-expand-lg bg-white navbar-light sticky-top py-0">
                    <button type="button" class="navbar-toggler me-0" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                        <span class="navbar-toggler-icon"></span>
                    </button>

                    <Link to="/" onClick={navClickedLogo} class="navbar-brand ps-4 me-0">
                        <img class="small-logo" src="img/IconOnlyTransparent.jpg" />
                        <h1 class="text-white m-0">צחי בן חמו הנדימן</h1>
                    </Link>
                    <div class="collapse navbar-collapse" id="navbarCollapse">
                        <div class="navbar-nav ms-auto p-4 p-lg-0">
                            <Link to="/" onClick={navClicked} class="daf_bait nav-item nav-link active">דף הבית</Link>
                            <Link to="/about" onClick={navClicked} class="nav-item nav-link about1">אודותי</Link>
                            <Link to="/service" onClick={navClicked} class="nav-item nav-link service1">שירותים</Link>
                            <Link to="/whyme" onClick={navClicked} class="nav-item nav-link whyme1">למה אני?</Link>
                            <Link to="/contact" onClick={navClicked} class="nav-item nav-link contact1">יצירת קשר</Link>
                        </div>
                    </div>

                </nav>
            </>
        )
    }

    return render();
}


export default Navbar;
