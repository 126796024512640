

const Features = ()=>{

    const render = ()=>{
        return(
        <>
            <div class="container-xxl py-5" id="whyme">
        <div class="container">
            <div class="row g-5 align-items-center">
                <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div class="position-relative me-lg-4">
                        <img class="img-fluid w-100" src="img/feature.jpg" alt="feature"/>
                    </div>
                </div>
                <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                    <h1 class="display-5 mb-4">כמה סיבות למה לבחור בי</h1>
                    <p class="mb-4">
                        לא קל בימנו למצוא איש מקצוע שייתן יחס הגון, עבודה מקצועית, וגם ייתן אחריות
                        ויהיה זמין עבורכם, לכל שינוי, תיקון, או שיפור.
                       אצלי הלקוח הוא קודם כל חבר,
                       ואני עושה את מירב המאמצים כדי שיהיה מרוצה.
                    </p>
                    <div class="row gy-4">
                        <div class="col-12">
                            <div class="d-flex">
                                <div class="flex-shrink-0 btn-lg-square rounded-circle bg-info">
                                    <i class="fa fa-check text-white"></i>
                                </div>
                                <div class="ms-4">
                                    <h4>עבודה מקצועית</h4>
                                    <span>כל משימה ניתן לבצע, אך עניין האיכות בגימור הוא מה שקובע אם לקוח יהיה מרוצה
                                        וכמובן עם אפס תקלות ונזקים סביבתיים, והשארת סביבה נקייה ללקוח שיכול להנות מהתוצר.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="d-flex">
                                <div class="flex-shrink-0 btn-lg-square rounded-circle bg-info">
                                    <i class="fa fa-check text-white"></i>
                                </div>
                                <div class="ms-4">
                                    <h4>מחיר, מחיר מחיר!</h4>
                                    <span>
                                        נכון. כפי שאני מצהיר תמיד, אני לא לוקח כסף עבור ביקור,
                                       ואני תמיד עוקב אחרי המחירים שמציעים בשוק ומציע הרבה פחות.                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="d-flex">
                                <div class="flex-shrink-0 btn-lg-square rounded-circle bg-info">
                                    <i class="fa fa-check text-white"></i>
                                </div>
                                <div class="ms-4">
                                    <h4>תמיכה ואחריות על העבודה</h4>
                                    <span>
                                        אחריות ותמיכה עבור עבודה, הוא גורם חשיבות מספר אחד ללקוחות מרוצים
                                        אנשי מקצוע בדר״כ חושבים שלקוח זה זבנג וגמרנוֿ, אך אצלי זה קשר חברי, ויחסי אמון הדדיים
                                        ואבוא פעם נוספת שיהיה בכך צורך.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            </div>
        </>
        )
    }

    return render();
}


export default Features;
