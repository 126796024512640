

const CustomersSay = () => {

    const render = () => {
        return (
            <>
                <div class="container-xxl py-5" style={{ direction: "ltr" }}>
                    <div class="container">
                        <div class="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: "600px" }}>
                            <h1 class="display-5 mb-5">מה הלקוחות שלנו אומרים</h1>
                        </div>
                        <div class="owl-carousel testimonial-carousel wow fadeInUp" data-wow-delay="0.1s">
                            <div class="testimonial-item text-center">
                                <div class="testimonial-img position-relative">
                                    <img alt="" class="img-fluid rounded-circle mx-auto mb-5" src="img/WhatsAppImage2021-11-11at08.29.54.webp" />
                                    <div class="btn-square bg-info rounded-circle">
                                        <i class="fa fa-quote-left text-white"></i>
                                    </div>
                                </div>
                                <div class="testimonial-text text-center rounded p-4">
                                    <p dir="rtl">
                                        צחי מקצוען אמיתי, היה אצלי הרכיב לי 5 גופי תאורה, 3 מאווררי תקרה, תקן לי את הקירות,
                                        והקל מאוד על המעבר דירה שלי, ממליץ מאוד, גם אמין, מדויק בזמנים, ולוקח אחריות על העבודה,
                                        הוא חזר לסובב עבורי מנורה ולתקן קצר חשמלי שהיה לי בגוף תאורה אחר ללא תמורה.
                                        לקחתי אותו 3 פעמים וגם אקח בעתיד, ממליץ מאוד!
                                    </p>
                                    <h5 class="mb-1">אביחי בן נעים</h5>
                                    <span class="fst-italic">רעננה</span>
                                </div>
                            </div>
                            <div class="testimonial-item text-center">
                                <div class="testimonial-img position-relative">
                                    <img alt="" class="img-fluid rounded-circle mx-auto mb-5" src="img/eli_shlomo.jpeg" />
                                    <div class="btn-square bg-info rounded-circle">
                                        <i class="fa fa-quote-left text-white"></i>
                                    </div>
                                </div>
                                <div class="testimonial-text text-center rounded p-4">
                                    <p>
                                        היה לי נזילה בשתי מזגנים,
                                        צחי הגיע פירק אותם ניקה, החליף צינור ניקוז לשתי המזגנים
                                        ועל הדרך תיקן לי פאנל שהיה מנותק בקיר,
                                        היה מאוד קשוב ,עשה את הכל במקצועיות, עמד בזמנים, ניקה הכל אחריו,
                                        ולקח מחיר הוגן.
                                    </p>
                                    <h5 class="mb-1">אלי שלמה</h5>
                                    <span class="fst-italic">ראש העין</span>
                                </div>
                            </div>
                            <div class="testimonial-item text-center">
                                <div class="testimonial-img position-relative">
                                    <img alt="" class="img-fluid rounded-circle mx-auto mb-5" src="img/anonimi.jpeg" />
                                    <div class="btn-square bg-info rounded-circle">
                                        <i class="fa fa-quote-left text-white"></i>
                                    </div>
                                </div>
                                <div class="testimonial-text text-center rounded p-4">
                                    <p>
                                        צחי בחור מאוד נעים, מקצוען, וקשוב.
                                        תיקן לי קירות בבית וצבע שתי חדרים בצבעים מאוד מאתגרים
                                        עם פסים וגבהים, השתמש בציוד משוכלל
                                        והתוצאה הייתה מדהימה במחיר שכיף להזמין אותו עוד הפעם
                                        ממליצה!
                                    </p>
                                    <h5 class="mb-1">רבקה בן ארצי</h5>
                                    <span class="fst-italic">ראש העין</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return render();
}


export default CustomersSay;
